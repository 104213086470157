import { selectModalData } from 'store';
import { useAppSelector } from 'store/hooks';
import { Modal } from './Modal';

export const ReduxDrivenModal = () => {
  const modalProps = useAppSelector(selectModalData);
  if (!modalProps) return null;

  return <Modal {...modalProps} />;
};
