export const toggleParams = (value: string | number, items: Array<string | number>) => {
  if (!items.includes(value)) {
    return [...items, value];
  } else {
    return items.filter((e) => e !== value);
  }
};

export const isEmpty = (obj?: Record<string, any> | null) => !Object.keys(obj ? obj : {}).length;

export const isEmptyFiltered = (obj?: Record<string, any> | null) => !Object.values(obj ? obj : {}).filter(i => !!i).length;
