import cx from 'classnames';
import styles from './Spinner.module.scss';

export type SpinnerSize = 'sm';

export type SpinnerProps = {
  size?: SpinnerSize;
  className?: string;
};

export const Spinner = ({ size = 'sm', className }: SpinnerProps) => {
  return (
    <div className={cx(styles.loader, styles[`size-${size}`], className)}></div>
  );
};
