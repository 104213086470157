import Image from 'next/legacy/image';

import styles from './SendyTeamLogo.module.scss';

export const SendyTeamLogo = () => {
  return (
    <div className={styles.systemSenderImage}>
      <Image src="/icons/vertical.svg" alt="SENDY Team" width={32} height="32" />
    </div>
  );
};
