import { useEffect, useState } from 'react';
import styles from './Cover.module.scss';
import { CoverProps } from 'types';
import cx from 'classnames';

export const Cover = ({ onCoverClick }: CoverProps) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(true);
  }, []);
  return <div className={cx(styles.cower, { [styles.opened]: isVisible })} onClick={onCoverClick} />;
};
