import Image from 'next/legacy/image';

import cx from 'classnames';
import styles from './NextImage.module.scss';

export type NextIconWithNotificationProps = {
  alt?: string;
  customNotificationClass?: string;
  height?: number | string;
  notification?: number;
  src: string;
  width?: number | string;
};

export const NextIconWithNotification = ({
  alt = '',
  customNotificationClass,
  height = '24',
  notification,
  src,
  width = '24',
}: NextIconWithNotificationProps) => {
  return (
    <div className={styles.notificationIcon} style={{height: `${height}px`, width: `${width}px`}}>
      <Image src={src} width={width} height={height} alt={alt} />
      {!!notification && (
        <p className={cx(styles.notification, customNotificationClass)}>{notification > 99 ? ':D' : notification}</p>
      )}
    </div>
  );
};
