import Image from 'next/legacy/image';
import { USER_PHOTO_PLACEHOLDER_LINK } from 'consts';
import { SendyTeamLogo } from 'components/SendyTeamLogo';

import cx from 'classnames';
import styles from './AccountAvatar.module.scss';

export type AccountAvatarProps = {
  photo?: string;
  sex: string;
  active?: boolean;
  isAdmin?: boolean;
  customClass?: string;
};

export const AccountAvatar = ({ photo, sex, active = false, isAdmin, customClass }: AccountAvatarProps) => {
  return (
    <div className={cx(styles.container, styles[`initials-${sex}`], { [styles.containerActive]: active }, customClass)}>
      {isAdmin ? (
        <SendyTeamLogo />
      ) : (
        <Image src={photo ? photo : USER_PHOTO_PLACEHOLDER_LINK} alt="avatar" layout="fill" objectFit="cover" />
      )}
    </div>
  );
};
