import { ReactElement } from 'react';
import Link from 'next/link';

export type NextLinkProps = {
  id?: string;
  href: string;
  children?: ReactElement | string;
  title?: string;
  className?: string;
  useDirectLink?: boolean;
  newTab?: boolean;
};

export const NextLink = ({ id, href, title, children, className, useDirectLink, newTab }: NextLinkProps) => {
  return (
    <>
      {useDirectLink ? (
        <a id={id} href={href} className={className} target={newTab ? '_blank' : '_self'} rel="noreferrer">
          {title && <p title={title}>{title}</p>}
          {children && children}
        </a>
      ) : (
        <Link id={id} href={href} className={className} target={newTab ? '_blank' : '_self'} rel="noreferrer">
          {title && <p title={title}>{title}</p>}
          {children && children}
        </Link>
      )}
    </>
  );
};
