import dynamic from 'next/dynamic';
import { JoinedFormFieldProps } from 'types';
import { DropMenuProps } from './DropMenu';

export const Dropdown = dynamic<JoinedFormFieldProps>(() => import('./Dropdown').then((module) => module.Dropdown));
export const ApiDropdown = dynamic<JoinedFormFieldProps>(() => import('./ApiDropdown').then((module) => module.ApiDropdown));
export const DropMenu = dynamic<DropMenuProps>(() => import('./DropMenu').then((module) => module.DropMenu));
export const DropdownCaret = dynamic<{ isOpen: boolean }>(() =>
  import('./DropdownCaret').then((module) => module.DropdownCaret),
);
