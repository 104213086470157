import dynamic from 'next/dynamic';
import { JoinedFormFieldProps } from 'types';
import {DynamicInputProps} from './DynamicInput'

export const DynamicInput = dynamic<DynamicInputProps>(() =>
  import('./DynamicInput').then((module) => module.DynamicInput),
);

export const Checkbox = dynamic<JoinedFormFieldProps>(() => import('./Checkbox').then((module) => module.Checkbox));

export const CharityCheckbox = dynamic<JoinedFormFieldProps>(() => import('./CharityCheckbox').then((module) => module.CharityCheckbox));

export const PhoneInput = dynamic<JoinedFormFieldProps>(() =>
  import('./PhoneInput').then((module) => module.PhoneInput),
);

export type { DynamicInputProps } from './DynamicInput';
